<template>
  <v-app class>
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto">
        <v-container>
          <div class="mb-4 mt-8 d-flex ">
            <div>
              <v-alert v-model="alert"
                       border="left"
                       dark
                       dismissible
                       class="alert"
                       :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}">
                {{alertMessage}}
              </v-alert>
            </div>
            <div class="col-md-11 text-center">
              <h1>Union Membership</h1>
            </div>
          </div>
           <!-- reject dialog  -->
              <v-dialog v-model="rejectDialog" persistent max-width="400">
                <v-card>
                  <v-card-title class="headline text-center"><h3>Reason for rejecting</h3></v-card-title>
                  <v-divider class="mx-4 mb-5"></v-divider>
                  <v-card-text class="mt-5">
                    <v-form ref="commentForm" v-model="valid">
                      <TextField  v-model="formData.comments" :label="'Comment'"/>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <Button
                      :label="'Reject'"
                      :btnType="'Submit'"
                      @onClick="onReject"
                      :isLoading="loading"
                      :disabled="!valid"
                      class="mr-4"
                    />
                    <v-btn color="green darken-1" text @click="rejectDialog = false">Close</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog >
            <!-- return dialog  -->

          <div v-if="loadingData" class="text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
           <v-data-table v-else
                         :headers='headers'
                         :items="unionMembership"
                         item-key="id">

                  <template v-slot:[`item.actions`]="{ item }">
                    <div class="d-flex">
                        <Button :btnType="'Submit'" :color="'success'" :label="'Approve'" @onClick="ApproveItem(item)" class="mr-2" />
                        <Button :btnType="'Cancel'" :label="'Reject'" @onClick="RejectItem(item)" class="mr-2" />
                    </div>
                    </template>
           </v-data-table>
        </v-container>
      </div>
    </div>
    <!-- <ConfirmationDialog :title="'Are you sure you want to Reject?'" :isLoading="loading" :dialog="rejectDialog" :btnTitle="'Yes'" @close="rejectDialog = false" @btnAction="onReject"/> -->

    <!-- <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="loadingData" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="onSave"/> -->

    <ConfirmationDialog :title="'Are you sure you want to Approve?'" :isLoading="loadingData" :dialog="dialogApprove" :btnTitle="'Yes'" @close="dialogApprove = false" @btnAction="onApprove"/>
  </v-app>
</template>
<script>
import Button from '@/components/ui/form/Button.vue'
// import Dialog from '@/components/ui/Dialog.vue'
// import SelectField from '@/components/ui/form/SelectField.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import { unionMemberService } from '@/services'
import TextField from '@/components/ui/form/TextField'
export default {
  components: {
    TextField,
    Button,
    ConfirmationDialog
    // Dialog
    // SelectField
  },
  data () {
    return {
      formData: {
        comments: ''
      },
      pay: [],
      approval: [],
      loadingData: false,
      loading: false,
      dialog: false,
      dialogApprove: false,
      rejectDialog: false,
      valid: false,
      unionMembership: [],
      alertMessage: '',
      alert: false,
      alertType: '',
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Description',
          value: 'description'
        },
        {
          text: 'MarkUp',
          value: 'markUp'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => {
        this.alert = false
      }, 5000)
    },

    getUnionMembership () {
      this.loadingData = true
      unionMemberService.getAllPendingUnionMembers().then(result => {
        this.unionMembership = result.data.items
      }).catch(error => {
        console.log(error)
      })
      this.loadingData = false
    },

    cancel () {
    },

    ApproveItem (item) {
      this.approval = item
      this.dialogApprove = true
    },
    onApprove () {
      this.loadingData = true
      console.log('Ok')
      const data = {
        Comment: '',
        Status: 1,
        Id: this.approval.id
      }
      unionMemberService.approveMembership(data).then(() => {
        this.showAlertMessage('Union membership successfully approved', 'success')
        this.loadingData = false
        this.dialogApprove = false
        this.getUnionMembership()
      }).catch((error) => {
        console.log('Error: ', error)
        this.showAlertMessage('Approval Union Membership failed', 'error')
        this.loadingData = false
        this.dialogApprove = false
        this.getUnionMembership()
      }).finally(() => {
        this.loadingData = false
        this.dialogApprove = false
        this.onReset()
      })
    },
    RejectItem (item) {
      this.rejectDialog = true
      this.pay = item
    },
    onReject () {
      this.loading = true
      const data = {
        Comments: this.formData.comments,
        Status: 2,
        Id: this.pay.id
      }
      unionMemberService.approveMembership(data).then(() => {
        this.showAlertMessage('Income Tax Band successfully Rejected', 'success')
        this.loading = false
        this.rejectDialog = false
        this.getUnionMembership()
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    }

  },
  mounted () {
    this.getUnionMembership()
  }
}
</script>
<style scoped>
  .search-btn {
    background-color: #e6e3e3;
    height: 40px;
    border-radius: 3px !important;
  }

  .alert {
    position: fixed;
    z-index: 9999;
    top: 5px;
    min-width: 50%;
  }

  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  .alert-error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  .row-pointer >>> tbody tr :hover {
    cursor: pointer;
  }

  .iconAvatar {
    width: 122px;
  }

  .text-caption {
    font-weight: 600;
    font-size: 14px !important
  }
  .sticky-col {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
  }
  .cursor {
    cursor: pointer;
  }

    .cursor:hover {
      color: blue
    }

    .cursor .tooltiptext {
      visibility: hidden;
      width: 80px;
      background-color: black;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 2px 0;
      font-size: 10px;
      /* Position the tooltip */
      position: absolute;
      z-index: 1;
    }

    .cursor:hover .tooltiptext {
      visibility: visible;
    }
</style>
